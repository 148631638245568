<template>
    <v-dialog v-model="dialog" max-width="600" persistent no-click-animation>
        <v-form ref="form" v-insert="{tagName:'input',attrs:{style:'display:none',type:'text'}}">
            <v-card>
                <v-card-title>
                    <div class="title">Add a Rule</div>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-layout column v-single-input>
                        <v-flex v-if="showText">
                            <span style="color: #666;" class="fs-3 font-weight-regular ">
                                By enabling the firewall feature, you will allow connections only from trusted IPs. Please add at least one public IP address/range so your instance can be accessed.
                            </span>
                        </v-flex>
                        <v-flex>
                            <v-text-field
                                    v-model="formData.ip_cird"
                                    :rules="[rules.required,rules.ipCidr]"
                                    persistent-hint
                                    hint=" e.g. 172.168.56.198 or 172.168.56.0/24."
                                    clearable>
                                <template v-slot:label>
                                    <RequiredDot>IP</RequiredDot>
                                </template>
                            </v-text-field>
                        </v-flex>
                        <v-flex class="mt-2">
                            <v-text-field
                                    v-model="formData.description"
                                    persistent-hint
                                    clearable>
                                <template v-slot:label>
                                    <RequiredDot>Description</RequiredDot>
                                </template>
                            </v-text-field>
                        </v-flex>
                        <v-flex class="mt-3" style="display: inline-block;text-align: right;">
                            <v-btn class="text-none" color="blue darken-1" flat @click="close()">Cancel</v-btn>
                            <v-btn class="text-none" color="primary" @click="ok()" :loading="loading">Create</v-btn>
                        </v-flex>
                    </v-layout>
                </v-card-text>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
    import dialogMixin from '@/module/mixins/loading.mixin'
    import rules from '@/module/rules/index.js'
    import {mapState} from 'vuex'

    export default {
        name: 'DelFtp',
        mixins: [dialogMixin],
        data() {
            return {
                rules,
                showText: false,
                formdata: {
                    ip_cird: '',
                    description: ''
                }
            }
        },
        computed: {
            ...mapState('application', ['appInfo']),
        },
        methods: {
            open(options) {
                this.showText = Boolean(!options)
                this.formData = Object.assign(this.formData,options);
                this.dialog = true
            },
            $validator(){
                return this.$refs.form.validate()
            }
        },
        watch: {
            dialog(val) {
                if (!val) {
                    this.$refs.form.reset()
                    this.formData.name=''
                }
            }
        }
    }
</script>