var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "600", persistent: "", "no-click-animation": "" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-form",
        {
          directives: [
            {
              name: "insert",
              rawName: "v-insert",
              value: {
                tagName: "input",
                attrs: { style: "display:none", type: "text" }
              },
              expression:
                "{tagName:'input',attrs:{style:'display:none',type:'text'}}"
            }
          ],
          ref: "form"
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _c("div", { staticClass: "title" }, [_vm._v("Add a Rule")])
              ]),
              _c("v-divider"),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-layout",
                    {
                      directives: [
                        { name: "single-input", rawName: "v-single-input" }
                      ],
                      attrs: { column: "" }
                    },
                    [
                      _vm.showText
                        ? _c("v-flex", [
                            _c(
                              "span",
                              {
                                staticClass: "fs-3 font-weight-regular ",
                                staticStyle: { color: "#666" }
                              },
                              [
                                _vm._v(
                                  "\n                            By enabling the firewall feature, you will allow connections only from trusted IPs. Please add at least one public IP address/range so your instance can be accessed.\n                        "
                                )
                              ]
                            )
                          ])
                        : _vm._e(),
                      _c(
                        "v-flex",
                        [
                          _c("v-text-field", {
                            attrs: {
                              rules: [_vm.rules.required, _vm.rules.ipCidr],
                              "persistent-hint": "",
                              hint: " e.g. 172.168.56.198 or 172.168.56.0/24.",
                              clearable: ""
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "label",
                                fn: function() {
                                  return [_c("RequiredDot", [_vm._v("IP")])]
                                },
                                proxy: true
                              }
                            ]),
                            model: {
                              value: _vm.formData.ip_cird,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "ip_cird", $$v)
                              },
                              expression: "formData.ip_cird"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { staticClass: "mt-2" },
                        [
                          _c("v-text-field", {
                            attrs: { "persistent-hint": "", clearable: "" },
                            scopedSlots: _vm._u([
                              {
                                key: "label",
                                fn: function() {
                                  return [
                                    _c("RequiredDot", [_vm._v("Description")])
                                  ]
                                },
                                proxy: true
                              }
                            ]),
                            model: {
                              value: _vm.formData.description,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "description", $$v)
                              },
                              expression: "formData.description"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        {
                          staticClass: "mt-3",
                          staticStyle: {
                            display: "inline-block",
                            "text-align": "right"
                          }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "text-none",
                              attrs: { color: "blue darken-1", flat: "" },
                              on: {
                                click: function($event) {
                                  return _vm.close()
                                }
                              }
                            },
                            [_vm._v("Cancel")]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "text-none",
                              attrs: { color: "primary", loading: _vm.loading },
                              on: {
                                click: function($event) {
                                  return _vm.ok()
                                }
                              }
                            },
                            [_vm._v("Create")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }