<template>
    <v-container class="panel-wrapper pa-0 panel-security" grid-list-lg fluid>
        <v-layout column>
            <v-flex>
                <TabCard title="Firewall Rules">
                    <v-layout column  v-if="ready">
                        <template>
<!--                            <v-flex shrink>-->
<!--                            <span style="color: #666;" class="fs-3 font-weight-regular ">-->
<!--                                Firewall rules define what kind of internet is allowed.-->
<!--                            </span>-->
<!--                            </v-flex>-->
                            <v-layout style="padding:8px">
                                <v-flex  shrink>
                                    <v-switch
                                            v-model="show"
                                            class="mt-2 pr-2"
                                            color="primary"
                                            dense
                                            hide-details
                                            @click="clickHandler"
                                    >
                                    </v-switch>
                                </v-flex>
                                <v-flex shrink>
                                    <v-alert
                                            :value="true"
                                            color="primary"
                                            icon="info"
                                            outline
                                    >
                                        <span class="fs-4 font-weight-medium">{{show? 'Only the listed IPs will be allowed to connect to your application.' : 'All connections to your instance will be allowed.'}}</span>
                                    </v-alert>
                                </v-flex>

                            </v-layout>
                        </template>
                        <template v-if="show">
                            <v-flex xs12>
                                <v-data-table
                                        :loading="loading"
                                        :headers="headersList"
                                        :items="rulesList"
                                        class="elevation-1"
                                        hide-actions>
                                    <template v-slot:items="{item}">
                                        <td>{{ item.ip_cird || '--' }}</td>
                                        <td>{{ item.description || '--' }}</td>
                                        <td>{{ formatDate(item.created,'MM/DD/YYYY') || '--'  }}</td>
                                        <td>
                                            <span style="color: #028e01" v-if="item.status=== 'Enabled'">{{item.status || '--' }}</span>
                                            <span style="color: #8e0000;" v-if="item.status=== 'Disabled'">{{item.status || '--' }}</span>
                                        </td>
                                        <td>
                                            <v-btn icon small @click="$refs.del.open(item)">
                                                <v-icon>delete</v-icon>
                                            </v-btn>
                                        </td>
                                    </template>
                                    <template v-slot:no-data>
                                        <div style="min-height: 192px;position: relative;">
                                            <Spin v-show="loading"></Spin>
                                            <Empty v-show="!loading"></Empty>
                                        </div>
                                    </template>
                                </v-data-table>
                            </v-flex>
                            <v-flex style="align-self: flex-end;" v-if="show">
                                <template>
                                    <span class="body-1 font-weight-regular">{{numberDetail.used_number}} used of {{numberDetail.total_number}}</span>
                                </template>
                            </v-flex>
                            <v-flex style="padding : 30px 0 0;">
                                <v-btn class="text-none" color="primary" @click="$refs.addRule.open(rulesList.length)">Add a Rule</v-btn>
                            </v-flex>
                        </template>
                    </v-layout>
                    <template v-else>
                        <fy-vue-skeleton
                                animation="fade"
                                :rowOptions="{padding : '16px'}"
                                :childOptions="skeletonOptions"
                        ></fy-vue-skeleton>
                    </template>
                    <Help :mod="['menu_tab_firewall_rules']" :product="appInfo.product_name"></Help>
                </TabCard>
            </v-flex>
        </v-layout>
        <Confirm ref="del" @confirm="deleteItem">
            <span slot="title">Delete Firewall Rule</span>
            <template v-slot:content="{options}">
                <span v-if="rulesList.length===1" style="font-weight: 700">The firewall feature will be automatically disabled when the last rule is deleted. </span>
                <span>Are you sure you want to delete the IP from the list? If you confirm the request, connections from this IP will be denied when the Rules switch is on.</span>
            </template>
        </Confirm>
        <Confirm ref='openRules' @confirm="ipRulesHandle">
            <span slot="title">Firewall Rule</span>
            <template v-slot:content="{options}">
                <span>{{show?'All connections to your instance will be allowed.':'Only the listed IPs will be allowed to connect to your application.'}}</span>
            </template>
        </Confirm>
        <AddRule ref="addRule" @confirm="addIpRule"></AddRule>
    </v-container>
</template>

<script>
    import TabCard from "@/components/TabCard"
    import AddRule from "./_source/AddRule"
    import Empty from '@/components/Empty'
    import Spin from '@/components/Spin'
    import Confirm from '@/components/Confirm.vue';
    import {mapGetters, mapState} from 'vuex'
    import {formatDate} from '@/module/utils/date';
    import Help from '@/components/Help'

    const skeletonOptions = [
        {type: 'repeat', list: [
                { span: 24, type: 'list', options: { list:[{ height: '36px', width:'100%',marginTop:'10px'}] } },
                { span: 24, type: 'list', options: { list:[{ height: '32px', width:'60%',marginTop:'10px'}] } },
                { span: 24, type: 'card', options: { height: '150px', width:'100%',marginTop:'10px' } },
                { span: 24, type: 'card', justify: 'flex-start', align: 'flex-start', options: { height: '32px', width:'10%' ,marginTop:'10px'} },
            ]}
    ]
    export default {
        data() {
            return {
                ready: false,
                loading: false,
                show:false,
                headersList: [
                    {text: 'IP CIDR', value: '', sortable: false, align: 'left'},
                    {text: 'Description', value: '', sortable: false, align: 'left'},
                    {text: 'Created', value: '', sortable: false, align: 'left'},
                    {text: 'Status', value: '', sortable: false, align: 'left'},
                    {text: '', value: 'opt', sortable: false, align: 'right'}
                ],
                rulesList: [
                ],
                numberDetail: {

                }
            }
        },
        components: {TabCard, AddRule, Empty, Spin, Confirm ,Help},
        computed: {
            ...mapGetters('application', ['getServiceId']),
            ...mapState('application', ['appInfo'])
        },
        methods: {
            formatDate,
            getAllRules() {
                this.loading = true
                return this.$http.get(`/app_rules/`, {
                    params: {
                        service_uuid: this.getServiceId(this.appInfo)
                    }
                }).then(res => {
                    this.loading = false
                    this.rulesList = Object.assign(this.rulesList, res.results)
                    this.rulesList = res.results
                    this.numberDetail = res.assist_data
                    return Promise.resolve(this.rulesList)
                }).catch((e) => {
                    this.loading = false
                    this.$message.error(e.detail)
                    return Promise.reject(e)
                })
            },
            addIpRule({ip_cird, description}) {
                return this.$http.post(`/app_rules/`, {
                    ip_cird,
                    service_uuid: this.getServiceId(this.appInfo),
                    description
                }).then(() => {
                    this.$message.success('The firewall rule was created successfully')
                    if(this.rulesList.length === 0) {
                        let obj = {
                            ip: ip_cird,
                            des: description
                        }
                        this.ipRulesHandle(obj).then(() => {
                            this.getAllRules()
                        })
                    }else{
                        this.getAllRules()
                    }
                }).catch(e => {
                    this.$message.error(e.detail)
                })
            },
            deleteItem(item) {
                return this.$http
                    .delete(`/app_rules/${item.id}/`,{
                        data: {
                            service_uuid: this.getServiceId(this.appInfo)
                        }
                    })
                    .then(() => {
                        this.$message.success(`Firewall Rule was deleted successfully.`);

                        if(this.rulesList.length === 1) {
                            this.getSwitchStatus()
                            this.getAllRules()
                        } else{
                            this.getAllRules()
                        }
                    })
                    .catch(() => {
                        this.$message.error(`Oops, the deletion request was failed to submit, please contact us at support@cloudclusters.io for this issue.`);
                    });
            },
            clickHandler(e) {
                e.preventDefault()
                this.getAllRules().then(res => {
                    if(res.length > 0) {
                        this.$refs.openRules.open()
                    } else{
                        this.$refs.addRule.open(res.length)
                    }

                })
            },
            ipRulesHandle(opt){
                    return this.$http.put(`/app_rules/open-rules/`, {
                        service_uuid: this.getServiceId(this.appInfo),
                        is_open: !this.show,
                        ip_cird : opt.ip,
                        description: opt.des
                    }).then(() => {
                        this.show = !this.show
                        this.getAllRules()
                        return Promise.resolve()
                    }).catch((e) => {
                        this.$message.error(e.detail)
                        return Promise.reject(e)
                    })

            },
            getSwitchStatus() {
                return this.$http.get(`/app_rules/open-rules/`, {
                    params: {
                        service_uuid: this.getServiceId(this.appInfo)
                    }
                }).then(res => {
                    this.show = res.is_open
                    this.ready = true
                }).catch((e) => {
                    this.$message.error(e.detail)
                })
            }
        },
        created() {
            this.appId = this.$route.params.appId
            this.skeletonOptions = skeletonOptions
            this.getAllRules()
            this.getSwitchStatus()
            this.$SDK.track({pageName:'Security',productName:this.appInfo.product_name})
        }
    }
</script>
<style lang="scss">
    .panel-security{
        .v-alert{
            padding:8px
        }
    }
</style>
