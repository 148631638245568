<template>
    <v-dialog v-model="dialog" persistent :max-width="maxWidth" no-click-animation lazy>
        <v-card>
            <v-card-title>
                <span class="title"><slot name="title" :options="formData"></slot></span>
            </v-card-title>
            <v-card-text>
                <v-container  pa-0 grid-list-lg>
                    <slot></slot>
                    <slot name="content" :options="formData">
                        {{formData.content}}
                    </slot>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <slot name="footer">
                    <v-btn class="text-none" color="blue darken-1" flat @click="close">Cancel</v-btn>
                    <v-btn class="text-none" color="primary" :loading="loading"  @click="ok">Confirm</v-btn>
                </slot>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import loadingMixin from '@/module/mixins/loading.mixin.js'
    export default {
        name: "Confirm",
        mixins: [loadingMixin],
        props : {
            maxWidth: {
                type:String,
                default : '400px'
            }
        },
        data() {
            return {
                formData:{
                    content:''
                }
            }
        },
        methods: {
            open(options){
                this.formData = Object.assign(this.formData,options);
                this.dialog = true
            }
        }
    }
</script>

<style lang="scss">

</style>
