var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "panel-wrapper pa-0 panel-security",
      attrs: { "grid-list-lg": "", fluid: "" }
    },
    [
      _c(
        "v-layout",
        { attrs: { column: "" } },
        [
          _c(
            "v-flex",
            [
              _c(
                "TabCard",
                { attrs: { title: "Firewall Rules" } },
                [
                  _vm.ready
                    ? _c(
                        "v-layout",
                        { attrs: { column: "" } },
                        [
                          [
                            _c(
                              "v-layout",
                              { staticStyle: { padding: "8px" } },
                              [
                                _c(
                                  "v-flex",
                                  { attrs: { shrink: "" } },
                                  [
                                    _c("v-switch", {
                                      staticClass: "mt-2 pr-2",
                                      attrs: {
                                        color: "primary",
                                        dense: "",
                                        "hide-details": ""
                                      },
                                      on: { click: _vm.clickHandler },
                                      model: {
                                        value: _vm.show,
                                        callback: function($$v) {
                                          _vm.show = $$v
                                        },
                                        expression: "show"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-flex",
                                  { attrs: { shrink: "" } },
                                  [
                                    _c(
                                      "v-alert",
                                      {
                                        attrs: {
                                          value: true,
                                          color: "primary",
                                          icon: "info",
                                          outline: ""
                                        }
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "fs-4 font-weight-medium"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.show
                                                  ? "Only the listed IPs will be allowed to connect to your application."
                                                  : "All connections to your instance will be allowed."
                                              )
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          _vm.show
                            ? [
                                _c(
                                  "v-flex",
                                  { attrs: { xs12: "" } },
                                  [
                                    _c("v-data-table", {
                                      staticClass: "elevation-1",
                                      attrs: {
                                        loading: _vm.loading,
                                        headers: _vm.headersList,
                                        items: _vm.rulesList,
                                        "hide-actions": ""
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "items",
                                            fn: function(ref) {
                                              var item = ref.item
                                              return [
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(item.ip_cird || "--")
                                                  )
                                                ]),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.description || "--"
                                                    )
                                                  )
                                                ]),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.formatDate(
                                                        item.created,
                                                        "MM/DD/YYYY"
                                                      ) || "--"
                                                    )
                                                  )
                                                ]),
                                                _c("td", [
                                                  item.status === "Enabled"
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            color: "#028e01"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.status ||
                                                                "--"
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  item.status === "Disabled"
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            color: "#8e0000"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.status ||
                                                                "--"
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ]),
                                                _c(
                                                  "td",
                                                  [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          icon: "",
                                                          small: ""
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.$refs.del.open(
                                                              item
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v("delete")
                                                        ])
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          },
                                          {
                                            key: "no-data",
                                            fn: function() {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "min-height": "192px",
                                                      position: "relative"
                                                    }
                                                  },
                                                  [
                                                    _c("Spin", {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value: _vm.loading,
                                                          expression: "loading"
                                                        }
                                                      ]
                                                    }),
                                                    _c("Empty", {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value: !_vm.loading,
                                                          expression: "!loading"
                                                        }
                                                      ]
                                                    })
                                                  ],
                                                  1
                                                )
                                              ]
                                            },
                                            proxy: true
                                          }
                                        ],
                                        null,
                                        false,
                                        652874866
                                      )
                                    })
                                  ],
                                  1
                                ),
                                _vm.show
                                  ? _c(
                                      "v-flex",
                                      {
                                        staticStyle: {
                                          "align-self": "flex-end"
                                        }
                                      },
                                      [
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "body-1 font-weight-regular"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.numberDetail.used_number
                                                ) +
                                                  " used of " +
                                                  _vm._s(
                                                    _vm.numberDetail
                                                      .total_number
                                                  )
                                              )
                                            ]
                                          )
                                        ]
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                                _c(
                                  "v-flex",
                                  { staticStyle: { padding: "30px 0 0" } },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "text-none",
                                        attrs: { color: "primary" },
                                        on: {
                                          click: function($event) {
                                            return _vm.$refs.addRule.open(
                                              _vm.rulesList.length
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v("Add a Rule")]
                                    )
                                  ],
                                  1
                                )
                              ]
                            : _vm._e()
                        ],
                        2
                      )
                    : [
                        _c("fy-vue-skeleton", {
                          attrs: {
                            animation: "fade",
                            rowOptions: { padding: "16px" },
                            childOptions: _vm.skeletonOptions
                          }
                        })
                      ],
                  _c("Help", {
                    attrs: {
                      mod: ["menu_tab_firewall_rules"],
                      product: _vm.appInfo.product_name
                    }
                  })
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "Confirm",
        {
          ref: "del",
          on: { confirm: _vm.deleteItem },
          scopedSlots: _vm._u([
            {
              key: "content",
              fn: function(ref) {
                var options = ref.options
                return [
                  _vm.rulesList.length === 1
                    ? _c("span", { staticStyle: { "font-weight": "700" } }, [
                        _vm._v(
                          "The firewall feature will be automatically disabled when the last rule is deleted. "
                        )
                      ])
                    : _vm._e(),
                  _c("span", [
                    _vm._v(
                      "Are you sure you want to delete the IP from the list? If you confirm the request, connections from this IP will be denied when the Rules switch is on."
                    )
                  ])
                ]
              }
            }
          ])
        },
        [
          _c("span", { attrs: { slot: "title" }, slot: "title" }, [
            _vm._v("Delete Firewall Rule")
          ])
        ]
      ),
      _c(
        "Confirm",
        {
          ref: "openRules",
          on: { confirm: _vm.ipRulesHandle },
          scopedSlots: _vm._u([
            {
              key: "content",
              fn: function(ref) {
                var options = ref.options
                return [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.show
                          ? "All connections to your instance will be allowed."
                          : "Only the listed IPs will be allowed to connect to your application."
                      )
                    )
                  ])
                ]
              }
            }
          ])
        },
        [
          _c("span", { attrs: { slot: "title" }, slot: "title" }, [
            _vm._v("Firewall Rule")
          ])
        ]
      ),
      _c("AddRule", { ref: "addRule", on: { confirm: _vm.addIpRule } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }