var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        persistent: "",
        "max-width": _vm.maxWidth,
        "no-click-animation": "",
        lazy: ""
      },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _c(
              "span",
              { staticClass: "title" },
              [_vm._t("title", null, { options: _vm.formData })],
              2
            )
          ]),
          _c(
            "v-card-text",
            [
              _c(
                "v-container",
                { attrs: { "pa-0": "", "grid-list-lg": "" } },
                [
                  _vm._t("default"),
                  _vm._t(
                    "content",
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.formData.content) +
                          "\n                "
                      )
                    ],
                    { options: _vm.formData }
                  )
                ],
                2
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._t("footer", [
                _c(
                  "v-btn",
                  {
                    staticClass: "text-none",
                    attrs: { color: "blue darken-1", flat: "" },
                    on: { click: _vm.close }
                  },
                  [_vm._v("Cancel")]
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "text-none",
                    attrs: { color: "primary", loading: _vm.loading },
                    on: { click: _vm.ok }
                  },
                  [_vm._v("Confirm")]
                )
              ])
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }